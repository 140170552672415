import { Link } from "gatsby"
import React from "react"
import ReactWOW from "react-wow"
import bannerObject from "../../assets/images/main-banner/banner-object.png"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"

const MainBanner = ({ bannerDetails }) => {
  const title = bannerDetails?.title
  const longDesc = bannerDetails?.longDesc
  const linkText = bannerDetails?.linkText
  const link = bannerDetails?.link
  const bannerImage = bannerDetails?.media?.localFile?.url || bannerObject

  return (
    <div className="banner-wrapper banner-wrapper-inner">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-7 col-md-12">
            <div className="banner-wrapper-content">
              <ReactWOW delay=".1s" animation="fadeInLeft">
                <h1>{title}</h1>
              </ReactWOW>
              <ReactWOW delay=".1s" animation="fadeInLeft">
                <ReactMarkdown
                  children={longDesc}
                  rehypePlugins={[rehypeRaw]}
                />
              </ReactWOW>
              <ReactWOW delay=".1s" animation="fadeInRight">
                <div className="btn-box">
                  <Link to={link} className="default-btn">
                    <i className="flaticon-right"></i>
                    {linkText} <span></span>
                  </Link>
                </div>
              </ReactWOW>
            </div>
          </div>
          <div className="col-lg-5  col-md-12">
            <div className="banner-wrapper-image">
              <ReactWOW className="img-bnr">
                <img src={bannerImage} alt="Banner4Shape" />
              </ReactWOW>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainBanner
