import { Link } from "gatsby"
import React from "react"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"

const ServicesArea = ({ homeSlug, servicesWeOfferDetails, homeDetailService }) => {

  const title = servicesWeOfferDetails?.title
  const longDesc = servicesWeOfferDetails?.longDesc

  const services = homeDetailService?.nodes
  return (
    <section className="services-area ptb-100 service-box-item-wrap">
      <div className="container-fluid">
        <div className="section-title">
          <h2>{title}</h2>
          <ReactMarkdown children={longDesc} rehypePlugins={[rehypeRaw]} />
        </div>
        <div className="row align-items-stretch">
          {services?.filter(item => {
            return item.home.slug === homeSlug
          })?.map((service, index) => {
            const serviceTitle = service?.serviceTitle
            const serviceLongDesc = service?.serviceLongDesc
            const serviceSlug = service?.serviceSolution?.slug
            return <div className="col-lg-3 col-md-6 col-sm-12" key={`${homeSlug}-${index}`}>
              <div className="single-services-box-item  ">
                <div className="icon">{index + 1}</div>
                <h3>
                  <Link to={`/services/${serviceSlug}`}>
                    {serviceTitle}
                  </Link>
                </h3>
                <ReactMarkdown children={serviceLongDesc} rehypePlugins={[rehypeRaw]} />
                <Link className="learn-more-btn position-absolute" to={`/services/${serviceSlug}`}>
                  <i className="flaticon-right"></i> View details
                </Link>
                <div className="shape"></div>
              </div>
            </div>
          })}
        </div>
      </div>
    </section>
  )
}

export default ServicesArea
