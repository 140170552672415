import React from "react"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import MapShape2 from "../../assets/images/shape/map-shape2.png"

const WhyChooseUs = ({ whyChooseUsDetails, ourPartnerExpertDetails }) => {
  const title = whyChooseUsDetails?.title
  const longDesc = whyChooseUsDetails?.longDesc

  const titleExpert = ourPartnerExpertDetails?.title
  const longDescExpert = ourPartnerExpertDetails?.longDesc
  const partnerOrExpertsIn = ourPartnerExpertDetails?.partnerOrExpertsIn

  return (
    <section className="why-choose-us-area startup-consultancy-outer-wrap bg-color">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="why-choose-us-content">
              <h3>{title}</h3>
              <ReactMarkdown children={longDesc} rehypePlugins={[rehypeRaw]} />
              <div className="map-shape2">
                <img src={MapShape2} alt="MapShape" />
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="our-brand-partners-list ">
              <h3>{titleExpert}</h3>
              <div className="border-st">
                <ul className="list-unstyled  mt-5 ps-0">
                  {partnerOrExpertsIn?.map((experts, index) => (
                    <li key={index}>
                      <span>{index + 1}</span>
                      {experts.title}
                    </li>
                  ))}
                </ul>
              </div>
              <ReactMarkdown
                children={longDescExpert}
                rehypePlugins={[rehypeRaw]}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhyChooseUs
