import { graphql } from "gatsby"
import React from "react"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import MainBanner from "../components/HomeDetails/MainBanner"
import OurStrategic from "../components/HomeDetails/OurStrategic"
import ServicesArea from "../components/HomeDetails/ServicesArea"
import WeServe from "../components/HomeDetails/WeServe"
import WhyDoStartups from "../components/HomeDetails/WhyDoStartups"
import Seo from "../components/App/Seo"

const HomeDetail = ({ data }) => {
  const { metaTags, homeDetails, homeDetailService } = data
  const homeSlug = homeDetails?.slug
  const bannerDetails = homeDetails?.banner
  const whyChooseUsDetails = homeDetails?.whyChooseUs
  const ourPartnerExpertDetails = homeDetails?.ourPartnerExpert
  const engagementProcess = homeDetails?.engagementProcess
  const servicesWeOfferDetails = homeDetails?.servicesWeOffer
  const finalDeliverablesDetails = homeDetails?.finalDeliverables
  const { metaImage } = metaTags

  const pageMeta = metaTags?.metas?.filter(item => {
    return item.page === homeSlug
  })[0]
  return (
    <Layout>
      <Seo
        title={pageMeta?.metaTitle}
        description={pageMeta?.metaDesc}
        keywords={pageMeta?.metaKeyword}
        metaImage={metaImage}
        url={homeSlug}
      />
      <Navbar />
      <MainBanner bannerDetails={bannerDetails} />
      <WhyDoStartups
        whyChooseUsDetails={whyChooseUsDetails}
        ourPartnerExpertDetails={ourPartnerExpertDetails}
      />
      <OurStrategic engagementProcess={engagementProcess} />
      <ServicesArea
        homeSlug={homeSlug}
        servicesWeOfferDetails={servicesWeOfferDetails}
        homeDetailService={homeDetailService}
      />
      <WeServe finalDeliverablesDetails={finalDeliverablesDetails} />
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query GetSingleHomeDetails($slug: String) {
    metaTags: strapiMetaTags {
      metaImage {
        url
      }
      metas {
        id
        page
        metaTitle
        metaKeyword
        metaDesc
      }
    }
    homeDetails: strapiHome(slug: { eq: $slug }) {
      id
      title
      slug
      longDesc
      banner {
        id
        link
        linkText
        longDesc
        title
        media {
          localFile {
            url
          }
        }
      }
      whyChooseUs {
        id
        title
        longDesc
      }
      ourPartnerExpert {
        id
        title
        longDesc
        partnerOrExpertsIn {
          id
          title
        }
      }
      engagementProcess {
        id
        title
        desc
        engagementProcesList {
          id
          title
          longDesc
        }
      }
      servicesWeOffer {
        title
        longDesc
      }
      finalDeliverables {
        id
        title
        longDesc
        industriesOrDeliverables {
          id
          name
        }
      }
    }
    homeDetailService: allStrapiHomeDetailService(
      sort: { fields: id, order: ASC }
    ) {
      nodes {
        id
        serviceTitle
        serviceLongDesc
        home {
          id
          slug
        }
        serviceSolution {
          id
          title
          slug
        }
      }
    }
  }
`

export default HomeDetail
