import React from "react"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import VectorShape11 from "../../assets/images/shape/vector-shape11.png"

const WeServe = ({ finalDeliverablesDetails: deliverables }) => {
  const title = deliverables?.title
  const longDesc = deliverables?.longDesc
  const industriesOrDeliverables = deliverables?.industriesOrDeliverables
  return (
    <section className="industries-serve-area  new-services-sec bg-fafafb pt-100 pb-70">
      <div className="container">
        <div className="section-title">
          <h2>{title}</h2>
          <ReactMarkdown children={longDesc} rehypePlugins={[rehypeRaw]} />
        </div>

        <div className="row">
          {industriesOrDeliverables?.map((deliverable, index) => {
            const { name } = deliverable
            return (
              <div className="col-lg-3 col-sm-6 col-md-6 mb-3" key={index}>
                <div className="single-industries-serve-box">{name}</div>
              </div>
            )
          })}
        </div>
      </div>

      <div className="vector-shape11">
        {/* FIXME: Background Side Image - Make it dynamic .i.e it should come from strapi */}
        <img src={VectorShape11} alt="Vector Shape" />
      </div>
    </section>
  )
}

export default WeServe
