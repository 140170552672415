import React from "react"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"

const WhyChooseUs = ({ engagementProcess }) => {
  const title = engagementProcess?.title
  const desc = engagementProcess?.desc
  const engagementProcessList
    = engagementProcess?.engagementProcesList
  const classesArray = ['understand', 'analyze', 'deliver', 'continuous-support']

  return (
    <section className="why-choose-us-area bg-color">
      <div className="container">
        <div className="row">
          <div className="col">
            <section className="strategic-process-wrapper">
              <h2>{title}</h2>
              <p>
                {desc}
              </p>
              <div className="strategic-process">
                {engagementProcessList?.map((process, index) => {
                  const { title, longDesc } = process
                  return <div className="section" key={index}>
                    <div className={`card-header ${classesArray[index % classesArray.length]}`}>
                      <div className="card-inner">
                        <div className="card-count"> {index + 1}</div>
                        <h3>{title}</h3>
                      </div>
                    </div>
                    <div className="content-section">
                      <ReactMarkdown children={longDesc} rehypePlugins={[rehypeRaw]} />
                    </div>
                  </div>
                })}

                {/* <div className="section">
                  <div className="card-header analyze">
                    <div className="card-inner">
                      <div className="card-count">2</div>
                      <h3>Analyze </h3>
                    </div>
                  </div>
                  <div className="content-section">
                    <p> Our skilled architects start their process by</p>
                    <ul className="content-list">
                      <li>
                        {" "}
                        <i className="bx bx-check mr-3 "></i>Analyzing scope
                      </li>
                      <li>
                        {" "}
                        <i className="bx bx-check mr-3 "></i> Defining the key
                        system components
                      </li>
                      <li>
                        {" "}
                        <i className="bx bx-check mr-3 "></i>Selecting the most
                        appropriate technology stack
                      </li>
                      <li>
                        {" "}
                        <i className="bx bx-check mr-3 "></i>Developing a
                        comprehensive architecture design
                      </li>
                      <li>
                        {" "}
                        <i className="bx bx-check mr-3 "></i>Documenting
                        everything throughout
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="section">
                  <div className="card-header deliver">
                    <div className="card-inner">
                      <div className="card-count">3</div>
                      <h3>Continuous Support</h3>
                    </div>
                  </div>
                  <div className="content-section">
                    <p>
                      {" "}
                      Consolidating our findings and solutions, primarily into
                      three documents{" "}
                    </p>
                    <ul className="content-list">
                      <li>
                        {" "}
                        <i className="bx bx-check mr-3 "></i>Application
                        Architecture Document
                      </li>
                      <li>
                        {" "}
                        <i className="bx bx-check mr-3 "></i>Infrastructure
                        Architecture Document
                      </li>
                      <li>
                        {" "}
                        <i className="bx bx-check mr-3 "></i>Security
                        Architecture Document
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="section">
                  <div className="card-header continuous-support">
                    <div className="card-inner">
                      <div className="card-count">4</div>
                      <h3>Understand</h3>
                    </div>
                  </div>
                  <div className="content-section">
                    <p>
                      Your success means the world to us! We would like to
                      support you in further processes such as{" "}
                    </p>
                    <ul className="content-list">
                      <li>
                        <i className="bx bx-check mr-3 "></i> Vendor/Team
                        selection
                      </li>
                      <li>
                        <i className="bx bx-check mr-3 "></i> Assisting
                        Dev/Infra teams
                      </li>
                      <li>
                        <i className="bx bx-check mr-3 "></i> Auditing team’s
                        progress
                      </li>
                    </ul>
                  </div>
                </div> */}
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhyChooseUs
